import {useLogger, useAuth} from '#imports';
import {ref, computed} from 'vue';
import {storeToRefs} from 'pinia';
import type {ServiceLevelType} from '../openapi';
import {Configuration, ServiceLevelApi, ServiceLevelType as ServiceLevel} from '../openapi';

export function useServiceLevel() {
    const logger = useLogger();
    const {user} = storeToRefs(useAuth());

    const configuration = new Configuration({basePath: ''});
    const serviceLevelApiEndPoints = new ServiceLevelApi(configuration);

    const serviceLevel = ref<ServiceLevelType>();

    const serviceLevelType = computed(() => {
        if (serviceLevel.value === ServiceLevel.Standard) {
            return 'STARTER';
        }
        if (serviceLevel.value === ServiceLevel.Advanced) {
            return 'ADVANCED';
        }
        if (serviceLevel.value === ServiceLevel.Corporate) {
            return 'ENTERPRISE';
        }
        return '';
    });

    async function initServiceLevel() {
        if (serviceLevel.value) {
            return;
        }
        serviceLevel.value = (user.value?.extraInformation?.serviceLevel as ServiceLevelType) ?? (await getServiceLevelStatus());
    }

    async function getServiceLevelStatus(): Promise<ServiceLevelType | undefined> {
        try {
            const serviceLevelStatus = await serviceLevelApiEndPoints.getStatusOnServiceLevel();

            return serviceLevelStatus?.currentServiceLevelType;
        } catch (e) {
            logger.error('Cannot get service level status');
        }
    }

    return {
        serviceLevel,
        serviceLevelType,
        initServiceLevel,
    };
}
