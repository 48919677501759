/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClientOnboardingCompanyFreelancersJobStep = {
    NotStarted: 'NOT_STARTED',
    FreelancerPool: 'FREELANCER_POOL',
    FavoriteList: 'FAVORITE_LIST',
    Done: 'DONE'
} as const;
export type ClientOnboardingCompanyFreelancersJobStep = typeof ClientOnboardingCompanyFreelancersJobStep[keyof typeof ClientOnboardingCompanyFreelancersJobStep];


export function instanceOfClientOnboardingCompanyFreelancersJobStep(value: any): boolean {
    for (const key in ClientOnboardingCompanyFreelancersJobStep) {
        if (Object.prototype.hasOwnProperty.call(ClientOnboardingCompanyFreelancersJobStep, key)) {
            // @ts-ignore
            if (ClientOnboardingCompanyFreelancersJobStep[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClientOnboardingCompanyFreelancersJobStepFromJSON(json: any): ClientOnboardingCompanyFreelancersJobStep {
    return ClientOnboardingCompanyFreelancersJobStepFromJSONTyped(json, false);
}

export function ClientOnboardingCompanyFreelancersJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboardingCompanyFreelancersJobStep {
    return json as ClientOnboardingCompanyFreelancersJobStep;
}

export function ClientOnboardingCompanyFreelancersJobStepToJSON(value?: ClientOnboardingCompanyFreelancersJobStep | null): any {
    return value as any;
}

