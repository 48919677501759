/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ServiceLevelStatusResourcePendingRequest } from './';
import {
    ServiceLevelStatusResourcePendingRequestFromJSON,
    ServiceLevelStatusResourcePendingRequestFromJSONTyped,
    ServiceLevelStatusResourcePendingRequestToJSON,
} from './';
import type { ServiceLevelTransitionVerificationResource } from './';
import {
    ServiceLevelTransitionVerificationResourceFromJSON,
    ServiceLevelTransitionVerificationResourceFromJSONTyped,
    ServiceLevelTransitionVerificationResourceToJSON,
} from './';
import type { ServiceLevelType } from './';
import {
    ServiceLevelTypeFromJSON,
    ServiceLevelTypeFromJSONTyped,
    ServiceLevelTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceLevelStatusResource
 */
export interface ServiceLevelStatusResource {
    /**
     * 
     * @type {ServiceLevelType}
     * @memberof ServiceLevelStatusResource
     */
    currentServiceLevelType: ServiceLevelType;
    /**
     * 
     * @type {Array<ServiceLevelTransitionVerificationResource>}
     * @memberof ServiceLevelStatusResource
     */
    forbiddenTransitions?: Array<ServiceLevelTransitionVerificationResource>;
    /**
     * 
     * @type {ServiceLevelStatusResourcePendingRequest}
     * @memberof ServiceLevelStatusResource
     */
    pendingRequest?: ServiceLevelStatusResourcePendingRequest;
}

/**
 * Check if a given object implements the ServiceLevelStatusResource interface.
 */
export function instanceOfServiceLevelStatusResource(value: object): value is ServiceLevelStatusResource {
    if (!('currentServiceLevelType' in value) || value['currentServiceLevelType'] === undefined) return false;
    return true;
}

export function ServiceLevelStatusResourceFromJSON(json: any): ServiceLevelStatusResource {
    return ServiceLevelStatusResourceFromJSONTyped(json, false);
}

export function ServiceLevelStatusResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelStatusResource {
    if (json == null) {
        return json;
    }
    return {
        
        'currentServiceLevelType': ServiceLevelTypeFromJSON(json['currentServiceLevelType']),
        'forbiddenTransitions': json['forbiddenTransitions'] == null ? undefined : ((json['forbiddenTransitions'] as Array<any>).map(ServiceLevelTransitionVerificationResourceFromJSON)),
        'pendingRequest': json['pendingRequest'] == null ? undefined : ServiceLevelStatusResourcePendingRequestFromJSON(json['pendingRequest']),
    };
}

export function ServiceLevelStatusResourceToJSON(value?: ServiceLevelStatusResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currentServiceLevelType': ServiceLevelTypeToJSON(value['currentServiceLevelType']),
        'forbiddenTransitions': value['forbiddenTransitions'] == null ? undefined : ((value['forbiddenTransitions'] as Array<any>).map((v) => ServiceLevelTransitionVerificationResourceToJSON(v))),
        'pendingRequest': ServiceLevelStatusResourcePendingRequestToJSON(value['pendingRequest']),
    };
}

