/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OnboardingVisibilityState = {
    Closed: 'CLOSED',
    Collapsed: 'COLLAPSED',
    Expanded: 'EXPANDED'
} as const;
export type OnboardingVisibilityState = typeof OnboardingVisibilityState[keyof typeof OnboardingVisibilityState];


export function instanceOfOnboardingVisibilityState(value: any): boolean {
    for (const key in OnboardingVisibilityState) {
        if (Object.prototype.hasOwnProperty.call(OnboardingVisibilityState, key)) {
            // @ts-ignore
            if (OnboardingVisibilityState[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OnboardingVisibilityStateFromJSON(json: any): OnboardingVisibilityState {
    return OnboardingVisibilityStateFromJSONTyped(json, false);
}

export function OnboardingVisibilityStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingVisibilityState {
    return json as OnboardingVisibilityState;
}

export function OnboardingVisibilityStateToJSON(value?: OnboardingVisibilityState | null): any {
    return value as any;
}

