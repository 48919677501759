/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ClientOnboardingJob } from './';
import {
    ClientOnboardingJobFromJSON,
    ClientOnboardingJobFromJSONTyped,
    ClientOnboardingJobToJSON,
} from './';
import type { OnboardingVisibilityState } from './';
import {
    OnboardingVisibilityStateFromJSON,
    OnboardingVisibilityStateFromJSONTyped,
    OnboardingVisibilityStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientOnboardingRequest
 */
export interface ClientOnboardingRequest {
    /**
     * 
     * @type {ClientOnboardingJob}
     * @memberof ClientOnboardingRequest
     */
    startOrAdvanceJob?: ClientOnboardingJob;
    /**
     * 
     * @type {OnboardingVisibilityState}
     * @memberof ClientOnboardingRequest
     */
    setVisibility?: OnboardingVisibilityState;
}

/**
 * Check if a given object implements the ClientOnboardingRequest interface.
 */
export function instanceOfClientOnboardingRequest(value: object): value is ClientOnboardingRequest {
    return true;
}

export function ClientOnboardingRequestFromJSON(json: any): ClientOnboardingRequest {
    return ClientOnboardingRequestFromJSONTyped(json, false);
}

export function ClientOnboardingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboardingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'startOrAdvanceJob': json['startOrAdvanceJob'] == null ? undefined : ClientOnboardingJobFromJSON(json['startOrAdvanceJob']),
        'setVisibility': json['setVisibility'] == null ? undefined : OnboardingVisibilityStateFromJSON(json['setVisibility']),
    };
}

export function ClientOnboardingRequestToJSON(value?: ClientOnboardingRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startOrAdvanceJob': ClientOnboardingJobToJSON(value['startOrAdvanceJob']),
        'setVisibility': OnboardingVisibilityStateToJSON(value['setVisibility']),
    };
}

