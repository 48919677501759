/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ServiceLevelType } from './';
import {
    ServiceLevelTypeFromJSON,
    ServiceLevelTypeFromJSONTyped,
    ServiceLevelTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceLevelWithFeesResource
 */
export interface ServiceLevelWithFeesResource {
    /**
     * 
     * @type {ServiceLevelType}
     * @memberof ServiceLevelWithFeesResource
     */
    name?: ServiceLevelType;
    /**
     * 
     * @type {string}
     * @memberof ServiceLevelWithFeesResource
     */
    regularFees?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLevelWithFeesResource
     */
    strategyFees?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLevelWithFeesResource
     */
    expenseFees?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLevelWithFeesResource
     */
    maltOpenFees?: string;
}

/**
 * Check if a given object implements the ServiceLevelWithFeesResource interface.
 */
export function instanceOfServiceLevelWithFeesResource(value: object): value is ServiceLevelWithFeesResource {
    return true;
}

export function ServiceLevelWithFeesResourceFromJSON(json: any): ServiceLevelWithFeesResource {
    return ServiceLevelWithFeesResourceFromJSONTyped(json, false);
}

export function ServiceLevelWithFeesResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelWithFeesResource {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : ServiceLevelTypeFromJSON(json['name']),
        'regularFees': json['regularFees'] == null ? undefined : json['regularFees'],
        'strategyFees': json['strategyFees'] == null ? undefined : json['strategyFees'],
        'expenseFees': json['expenseFees'] == null ? undefined : json['expenseFees'],
        'maltOpenFees': json['maltOpenFees'] == null ? undefined : json['maltOpenFees'],
    };
}

export function ServiceLevelWithFeesResourceToJSON(value?: ServiceLevelWithFeesResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': ServiceLevelTypeToJSON(value['name']),
        'regularFees': value['regularFees'],
        'strategyFees': value['strategyFees'],
        'expenseFees': value['expenseFees'],
        'maltOpenFees': value['maltOpenFees'],
    };
}

