<template>
    <SudosuFrame />
    <Navbar />
    <TermsAndConditionsPopup v-if="loggedIn" />
    <GDPRPopup v-if="loggedIn" />
    <div class="profile-id-or-alias">
        <slot />
    </div>
    <FooterUnlogged />
    <FooterAdminTools v-if="isLoggedAsAdmin" />
    <ClientOnboarding />
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {FooterUnlogged, FooterAdminTools, GDPRPopup, Navbar, SudosuFrame, TermsAndConditionsPopup, ClientOnboarding} from '#components';
    import {useAuth, useHasEnoughPriviledge} from '#imports';
    import {storeToRefs} from 'pinia';

    const isAdmin = await useHasEnoughPriviledge(['ROLE_ADMIN']);
    const auth = useAuth();
    const {loggedIn} = storeToRefs(auth);

    const isLoggedAsAdmin = computed(() => loggedIn && isAdmin);
</script>
