/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServiceLevelVerificationErrorType = {
    PublicEmail: 'PUBLIC_EMAIL',
    BlacklistedEmailDomain: 'BLACKLISTED_EMAIL_DOMAIN',
    PhoneNumberMissing: 'PHONE_NUMBER_MISSING',
    OngoingProjects: 'ONGOING_PROJECTS',
    PendingRequest: 'PENDING_REQUEST'
} as const;
export type ServiceLevelVerificationErrorType = typeof ServiceLevelVerificationErrorType[keyof typeof ServiceLevelVerificationErrorType];


export function instanceOfServiceLevelVerificationErrorType(value: any): boolean {
    for (const key in ServiceLevelVerificationErrorType) {
        if (Object.prototype.hasOwnProperty.call(ServiceLevelVerificationErrorType, key)) {
            // @ts-ignore
            if (ServiceLevelVerificationErrorType[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ServiceLevelVerificationErrorTypeFromJSON(json: any): ServiceLevelVerificationErrorType {
    return ServiceLevelVerificationErrorTypeFromJSONTyped(json, false);
}

export function ServiceLevelVerificationErrorTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelVerificationErrorType {
    return json as ServiceLevelVerificationErrorType;
}

export function ServiceLevelVerificationErrorTypeToJSON(value?: ServiceLevelVerificationErrorType | null): any {
    return value as any;
}

