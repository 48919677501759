import {type ClientOnboardingJobs, type ClientOnboardingState, Configuration, OnboardingApi, ServiceLevelType as ServiceLevel} from '../openapi';
import {computed, navbarEventBus, ref, useLogger, useAuth} from '#imports';
import {defineStore, storeToRefs} from 'pinia';
import {onUnmounted} from 'vue';
import {useDisplayMobile} from '#malt/nuxt-utils-module';
import {
    browseProfileSteps,
    companyFreelancersSteps,
    discoverMaltStrategySteps,
    findProfileUsingAISteps,
    inviteFreelancerSteps,
    type StepInfo,
} from '../jobsSteps';
import {useClientOnboardingTracking} from '../composables/useClientOnboardingTracking';
import {useServiceLevel} from '../composables/useServiceLevel';
import {useFeatureFlagsStore} from './feature-flags.store';

enum OnboardingJobs {
    BROWSE_PROFILES = 'browseProfiles',
    FIND_PROFILES_USING_AI = 'findProfileUsingAi',
    INVITE_FREELANCER = 'inviteFreelancer',
    COMPANY_FREELANCERS = 'companyFreelancers',
    DISCOVER_MALT_STRATEGY = 'discoverMaltStrategy',
}

const apiConfiguration = new Configuration({basePath: ''});
const api = new OnboardingApi(apiConfiguration);

export const useOnboarding = defineStore('client-onboarding', () => {
    const logger = useLogger();
    const {isDisplayMobile} = useDisplayMobile();
    const {trackClientOnboardingReopened} = useClientOnboardingTracking();

    const onboardingState = ref<ClientOnboardingState>();

    async function initOnboardingState(): Promise<ClientOnboardingState | undefined> {
        try {
            onboardingState.value = await api.getClientOnboardingState();

            await setWalkthroughToCollapseIfNeeded(onboardingState.value);

            return onboardingState.value;
        } catch (e) {
            logger.error("Can't get onboarding client state", e);
            return undefined;
        }
    }

    // We immediatly set walkthrough to collapse because we want it to be expanded by default only once
    async function setWalkthroughToCollapseIfNeeded(onboardingState: ClientOnboardingState | undefined) {
        if (onboardingState?.visibility === 'EXPANDED') {
            await api.modifyClientOnboardingState({setVisibility: 'COLLAPSED'});
        }
    }

    const jobsCurrentSteps = computed<Map<keyof ClientOnboardingJobs, StepInfo>>(() => {
        const result = new Map<keyof ClientOnboardingJobs, StepInfo>();

        if (onboardingState.value?.jobsToBeDone.browseProfiles) {
            result.set(OnboardingJobs.BROWSE_PROFILES, browseProfileSteps[onboardingState.value.jobsToBeDone.browseProfiles]!);
        }

        if (onboardingState.value?.jobsToBeDone.findProfileUsingAi) {
            result.set(OnboardingJobs.FIND_PROFILES_USING_AI, findProfileUsingAISteps[onboardingState.value.jobsToBeDone.findProfileUsingAi]!);
        }

        if (onboardingState.value?.jobsToBeDone.inviteFreelancer) {
            result.set(OnboardingJobs.INVITE_FREELANCER, inviteFreelancerSteps[onboardingState.value.jobsToBeDone.inviteFreelancer]!);
        }

        if (onboardingState.value?.jobsToBeDone.companyFreelancers) {
            result.set(
                OnboardingJobs.COMPANY_FREELANCERS,
                companyFreelancersSteps(document.querySelector('[data-client-onboarding-freelancer-pool-favorite-list]'))[
                    onboardingState.value.jobsToBeDone.companyFreelancers
                ]!,
            );
        }
        if (onboardingState.value?.jobsToBeDone.discoverMaltStrategy) {
            result.set(OnboardingJobs.DISCOVER_MALT_STRATEGY, discoverMaltStrategySteps[onboardingState.value.jobsToBeDone.discoverMaltStrategy]!);
        }

        return result;
    });

    async function startOrAdvanceJob(job: keyof ClientOnboardingJobs) {
        try {
            onboardingState.value = await api.modifyClientOnboardingState({startOrAdvanceJob: job});
        } catch (e) {
            logger.error("Can't update onboarding client state", e);
        }
    }

    navbarEventBus.on('expand-client-onboarding-walkthrough', async () => {
        await expandWalkthrough();
        await trackClientOnboardingReopened(jobCompletionPercentage.value);
    });

    async function expandWalkthrough() {
        try {
            onboardingState.value = await api.modifyClientOnboardingState({setVisibility: 'EXPANDED'});
        } catch (e) {
            logger.error("Can't update onboarding client state", e);
        }
    }

    async function closeForever() {
        try {
            onboardingState.value = await api.modifyClientOnboardingState({setVisibility: 'CLOSED'});
        } catch (e) {
            logger.error("Can't update onboarding client state", e);
        }
    }

    const isWalkthroughExpanded = computed(() => (isDisplayMobile.value ? false : onboardingState.value?.visibility === 'EXPANDED'));
    const isOnboardingVisible = computed(() => onboardingState.value?.visibility !== 'CLOSED');
    const atLeastOneStepVisible = computed(
        () => onboardingState.value && Object.values(onboardingState.value.jobsToBeDone).some((step) => step !== undefined),
    );

    const allJobsFinished = computed(() => [...jobsCurrentSteps.value.values()].every((step) => step.buttonState === 'DONE'));

    const jobCompletionPercentage = computed(() => {
        const totalJobs = jobsCurrentSteps.value.size;
        const completedJobs = [...jobsCurrentSteps.value.values()].filter((step) => step.buttonState === 'DONE').length;
        return Math.round((completedJobs / totalJobs) * 100);
    });

    onUnmounted(() => {
        navbarEventBus.off('expand-client-onboarding-walkthrough', expandWalkthrough);
    });

    return {
        jobCompletionPercentage,
        allJobsFinished,
        jobsCurrentSteps,
        isWalkthroughExpanded,
        isOnboardingVisible,
        atLeastOneStepVisible,
        initOnboardingState,
        startOrAdvanceJob,
        closeForever,
    };
});
