
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  ClientOnboardingRequest,
  ClientOnboardingState,
} from '../models';
import {
    ClientOnboardingRequestFromJSON,
    ClientOnboardingRequestToJSON,
    ClientOnboardingStateFromJSON,
    ClientOnboardingStateToJSON,
} from '../models';

export interface ModifyClientOnboardingStateRequest {
    clientOnboardingRequest: ClientOnboardingRequest;
}

/**
 * 
 */
export class OnboardingApi extends runtime.BaseAPI {

    /**
     */
    async getClientOnboardingStateRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<ClientOnboardingState>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<ClientOnboardingState>({
            path: `/client-settings/api/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=ClientOnboardingStateFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getClientOnboardingState(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<ClientOnboardingState> {
        const response = await this.getClientOnboardingStateRaw(initOverrides);
        return response._data;
    }

    /**
     */
    async modifyClientOnboardingStateRaw(requestParameters: ModifyClientOnboardingStateRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<ClientOnboardingState>> {
        if (requestParameters['clientOnboardingRequest'] == null) {
            throw new runtime.RequiredError(
                'clientOnboardingRequest',
                'Required parameter "clientOnboardingRequest" was null or undefined when calling modifyClientOnboardingState().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<ClientOnboardingState>({
            path: `/client-settings/api/onboarding`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientOnboardingRequestToJSON(requestParameters['clientOnboardingRequest']),
        }, initOverrides);

        if (response._data) { response._data=ClientOnboardingStateFromJSON(response._data); }
        return response;
    }

    /**
     */
    async modifyClientOnboardingState(clientOnboardingRequest: ClientOnboardingRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<ClientOnboardingState> {
        const response = await this.modifyClientOnboardingStateRaw({ clientOnboardingRequest: clientOnboardingRequest }, initOverrides);
        return response._data;
    }

}
