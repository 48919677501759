/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ClientOnboardingBrowseProfilesJobStep } from './';
import {
    ClientOnboardingBrowseProfilesJobStepFromJSON,
    ClientOnboardingBrowseProfilesJobStepFromJSONTyped,
    ClientOnboardingBrowseProfilesJobStepToJSON,
} from './';
import type { ClientOnboardingCompanyFreelancersJobStep } from './';
import {
    ClientOnboardingCompanyFreelancersJobStepFromJSON,
    ClientOnboardingCompanyFreelancersJobStepFromJSONTyped,
    ClientOnboardingCompanyFreelancersJobStepToJSON,
} from './';
import type { ClientOnboardingDiscoverMaltStrategyJobStep } from './';
import {
    ClientOnboardingDiscoverMaltStrategyJobStepFromJSON,
    ClientOnboardingDiscoverMaltStrategyJobStepFromJSONTyped,
    ClientOnboardingDiscoverMaltStrategyJobStepToJSON,
} from './';
import type { ClientOnboardingFindProfileUsingAIJobStep } from './';
import {
    ClientOnboardingFindProfileUsingAIJobStepFromJSON,
    ClientOnboardingFindProfileUsingAIJobStepFromJSONTyped,
    ClientOnboardingFindProfileUsingAIJobStepToJSON,
} from './';
import type { ClientOnboardingInviteFreelancerJobStep } from './';
import {
    ClientOnboardingInviteFreelancerJobStepFromJSON,
    ClientOnboardingInviteFreelancerJobStepFromJSONTyped,
    ClientOnboardingInviteFreelancerJobStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientOnboardingJobs
 */
export interface ClientOnboardingJobs {
    /**
     * 
     * @type {ClientOnboardingBrowseProfilesJobStep}
     * @memberof ClientOnboardingJobs
     */
    browseProfiles?: ClientOnboardingBrowseProfilesJobStep;
    /**
     * 
     * @type {ClientOnboardingFindProfileUsingAIJobStep}
     * @memberof ClientOnboardingJobs
     */
    findProfileUsingAi?: ClientOnboardingFindProfileUsingAIJobStep;
    /**
     * 
     * @type {ClientOnboardingInviteFreelancerJobStep}
     * @memberof ClientOnboardingJobs
     */
    inviteFreelancer?: ClientOnboardingInviteFreelancerJobStep;
    /**
     * 
     * @type {ClientOnboardingCompanyFreelancersJobStep}
     * @memberof ClientOnboardingJobs
     */
    companyFreelancers?: ClientOnboardingCompanyFreelancersJobStep;
    /**
     * 
     * @type {ClientOnboardingDiscoverMaltStrategyJobStep}
     * @memberof ClientOnboardingJobs
     */
    discoverMaltStrategy?: ClientOnboardingDiscoverMaltStrategyJobStep;
}

/**
 * Check if a given object implements the ClientOnboardingJobs interface.
 */
export function instanceOfClientOnboardingJobs(value: object): value is ClientOnboardingJobs {
    return true;
}

export function ClientOnboardingJobsFromJSON(json: any): ClientOnboardingJobs {
    return ClientOnboardingJobsFromJSONTyped(json, false);
}

export function ClientOnboardingJobsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboardingJobs {
    if (json == null) {
        return json;
    }
    return {
        
        'browseProfiles': json['browseProfiles'] == null ? undefined : ClientOnboardingBrowseProfilesJobStepFromJSON(json['browseProfiles']),
        'findProfileUsingAi': json['findProfileUsingAi'] == null ? undefined : ClientOnboardingFindProfileUsingAIJobStepFromJSON(json['findProfileUsingAi']),
        'inviteFreelancer': json['inviteFreelancer'] == null ? undefined : ClientOnboardingInviteFreelancerJobStepFromJSON(json['inviteFreelancer']),
        'companyFreelancers': json['companyFreelancers'] == null ? undefined : ClientOnboardingCompanyFreelancersJobStepFromJSON(json['companyFreelancers']),
        'discoverMaltStrategy': json['discoverMaltStrategy'] == null ? undefined : ClientOnboardingDiscoverMaltStrategyJobStepFromJSON(json['discoverMaltStrategy']),
    };
}

export function ClientOnboardingJobsToJSON(value?: ClientOnboardingJobs | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'browseProfiles': ClientOnboardingBrowseProfilesJobStepToJSON(value['browseProfiles']),
        'findProfileUsingAi': ClientOnboardingFindProfileUsingAIJobStepToJSON(value['findProfileUsingAi']),
        'inviteFreelancer': ClientOnboardingInviteFreelancerJobStepToJSON(value['inviteFreelancer']),
        'companyFreelancers': ClientOnboardingCompanyFreelancersJobStepToJSON(value['companyFreelancers']),
        'discoverMaltStrategy': ClientOnboardingDiscoverMaltStrategyJobStepToJSON(value['discoverMaltStrategy']),
    };
}

