/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ServiceLevelType } from './';
import {
    ServiceLevelTypeFromJSON,
    ServiceLevelTypeFromJSONTyped,
    ServiceLevelTypeToJSON,
} from './';
import type { ServiceLevelVerificationErrorType } from './';
import {
    ServiceLevelVerificationErrorTypeFromJSON,
    ServiceLevelVerificationErrorTypeFromJSONTyped,
    ServiceLevelVerificationErrorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceLevelTransitionVerificationResource
 */
export interface ServiceLevelTransitionVerificationResource {
    /**
     * 
     * @type {ServiceLevelType}
     * @memberof ServiceLevelTransitionVerificationResource
     */
    transition: ServiceLevelType;
    /**
     * 
     * @type {Array<ServiceLevelVerificationErrorType>}
     * @memberof ServiceLevelTransitionVerificationResource
     */
    errors: Array<ServiceLevelVerificationErrorType>;
}

/**
 * Check if a given object implements the ServiceLevelTransitionVerificationResource interface.
 */
export function instanceOfServiceLevelTransitionVerificationResource(value: object): value is ServiceLevelTransitionVerificationResource {
    if (!('transition' in value) || value['transition'] === undefined) return false;
    if (!('errors' in value) || value['errors'] === undefined) return false;
    return true;
}

export function ServiceLevelTransitionVerificationResourceFromJSON(json: any): ServiceLevelTransitionVerificationResource {
    return ServiceLevelTransitionVerificationResourceFromJSONTyped(json, false);
}

export function ServiceLevelTransitionVerificationResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelTransitionVerificationResource {
    if (json == null) {
        return json;
    }
    return {
        
        'transition': ServiceLevelTypeFromJSON(json['transition']),
        'errors': ((json['errors'] as Array<any>).map(ServiceLevelVerificationErrorTypeFromJSON)),
    };
}

export function ServiceLevelTransitionVerificationResourceToJSON(value?: ServiceLevelTransitionVerificationResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'transition': ServiceLevelTypeToJSON(value['transition']),
        'errors': ((value['errors'] as Array<any>).map((v) => ServiceLevelVerificationErrorTypeToJSON(v))),
    };
}

