/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ServiceLevelType } from './';
import {
    ServiceLevelTypeFromJSON,
    ServiceLevelTypeFromJSONTyped,
    ServiceLevelTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceLevelChangeRequest
 */
export interface ServiceLevelChangeRequest {
    /**
     * 
     * @type {ServiceLevelType}
     * @memberof ServiceLevelChangeRequest
     */
    target: ServiceLevelType;
    /**
     * 
     * @type {string}
     * @memberof ServiceLevelChangeRequest
     */
    reason?: string;
}

/**
 * Check if a given object implements the ServiceLevelChangeRequest interface.
 */
export function instanceOfServiceLevelChangeRequest(value: object): value is ServiceLevelChangeRequest {
    if (!('target' in value) || value['target'] === undefined) return false;
    return true;
}

export function ServiceLevelChangeRequestFromJSON(json: any): ServiceLevelChangeRequest {
    return ServiceLevelChangeRequestFromJSONTyped(json, false);
}

export function ServiceLevelChangeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelChangeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'target': ServiceLevelTypeFromJSON(json['target']),
        'reason': json['reason'] == null ? undefined : json['reason'],
    };
}

export function ServiceLevelChangeRequestToJSON(value?: ServiceLevelChangeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'target': ServiceLevelTypeToJSON(value['target']),
        'reason': value['reason'],
    };
}

