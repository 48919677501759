/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { ClientOnboardingJobs } from './';
import {
    ClientOnboardingJobsFromJSON,
    ClientOnboardingJobsFromJSONTyped,
    ClientOnboardingJobsToJSON,
} from './';
import type { OnboardingVisibilityState } from './';
import {
    OnboardingVisibilityStateFromJSON,
    OnboardingVisibilityStateFromJSONTyped,
    OnboardingVisibilityStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientOnboardingState
 */
export interface ClientOnboardingState {
    /**
     * 
     * @type {ClientOnboardingJobs}
     * @memberof ClientOnboardingState
     */
    jobsToBeDone: ClientOnboardingJobs;
    /**
     * 
     * @type {OnboardingVisibilityState}
     * @memberof ClientOnboardingState
     */
    visibility: OnboardingVisibilityState;
}

/**
 * Check if a given object implements the ClientOnboardingState interface.
 */
export function instanceOfClientOnboardingState(value: object): value is ClientOnboardingState {
    if (!('jobsToBeDone' in value) || value['jobsToBeDone'] === undefined) return false;
    if (!('visibility' in value) || value['visibility'] === undefined) return false;
    return true;
}

export function ClientOnboardingStateFromJSON(json: any): ClientOnboardingState {
    return ClientOnboardingStateFromJSONTyped(json, false);
}

export function ClientOnboardingStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboardingState {
    if (json == null) {
        return json;
    }
    return {
        
        'jobsToBeDone': ClientOnboardingJobsFromJSON(json['jobsToBeDone']),
        'visibility': OnboardingVisibilityStateFromJSON(json['visibility']),
    };
}

export function ClientOnboardingStateToJSON(value?: ClientOnboardingState | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'jobsToBeDone': ClientOnboardingJobsToJSON(value['jobsToBeDone']),
        'visibility': OnboardingVisibilityStateToJSON(value['visibility']),
    };
}

