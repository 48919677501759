import {useDataTracking} from '#imports';
import type {ClientOnboardingJobs} from '../openapi';
import {useServiceLevel} from './useServiceLevel';

export function useClientOnboardingTracking() {
    const {track} = useDataTracking();
    const {serviceLevelType, initServiceLevel} = useServiceLevel();

    async function trackClientOnboardingJobStarted(job: keyof ClientOnboardingJobs, jobCompletionPercentage: number) {
        await initServiceLevel();
        const jobName = {
            browseProfiles: 'Browse profiles',
            findProfileUsingAi: 'Find profiles faster with AI matching',
            inviteFreelancer: 'Invite a freelancer',
            companyFreelancers: 'Company freelancers',
            discoverMaltStrategy: 'Discover Malt Strategy',
        };

        track('client_onboarding_job_started', {
            onboarding_job_name: jobName[job],
            url: window.location.pathname,
            percent_completion: `${jobCompletionPercentage}%`,
            current_service_level: serviceLevelType.value,
        });
    }

    async function trackClientOnboardingClosed(jobCompletionPercentage: number) {
        await initServiceLevel();
        track('client_onboarding_closed', {
            url: window.location.pathname,
            percent_completion: `${jobCompletionPercentage}%`,
            current_service_level: serviceLevelType.value,
        });
    }

    async function trackClientOnboardingReopened(jobCompletionPercentage: number) {
        await initServiceLevel();
        track('client_onboarding_reopened', {
            url: window.location.pathname,
            percent_completion: `${jobCompletionPercentage}%`,
            current_service_level: serviceLevelType.value,
        });
    }

    return {
        trackClientOnboardingJobStarted,
        trackClientOnboardingClosed,
        trackClientOnboardingReopened,
    };
}
