<template>
    <div id="client-onboarding-wrapper">
        <OnboardingWalkthrough v-if="shouldOpenWalkthrough" />
        <span v-else data-testid="client-onboarding-walkthrough-not-loaded" />
    </div>
</template>

<script setup lang="ts">
    import {computed, defineAsyncComponent} from 'vue';
    import {useAuth, callOnce} from '#imports';
    import {useOnboarding} from '../stores/onboardingClient.store';
    import {storeToRefs} from 'pinia';
    import {useFeatureFlagsStore, Flags} from '../stores/feature-flags.store';

    const {loadFeatureFlags} = useFeatureFlagsStore();
    const featureFlagStore = useFeatureFlagsStore();
    const {features} = storeToRefs(featureFlagStore);
    const auth = useAuth();
    const {currentIdentityIsClient} = storeToRefs(auth);

    const onboardingStore = useOnboarding();
    const {initOnboardingState} = onboardingStore;
    const {isOnboardingVisible, atLeastOneStepVisible} = storeToRefs(onboardingStore);

    await callOnce('load-client-onboarding-feature-flags', async () => await loadFeatureFlags());

    await initOnboardingStateIfNeeded();

    async function initOnboardingStateIfNeeded() {
        if (!currentIdentityIsClient.value) {
            return;
        }

        const isClientOnboardingV05O = features.value[Flags.NEW_CLIENT_ONBOARDING_V05];
        if (!isClientOnboardingV05O) {
            return;
        }

        await initOnboardingState();
    }

    const shouldOpenWalkthrough = computed(() => isOnboardingVisible.value && atLeastOneStepVisible.value);

    const OnboardingWalkthrough = defineAsyncComponent(() => import('./OnboardingWalkthrough.vue'));
</script>
