/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServiceLevelChangeRequestDirection = {
    Downgrade: 'DOWNGRADE',
    Upgrade: 'UPGRADE'
} as const;
export type ServiceLevelChangeRequestDirection = typeof ServiceLevelChangeRequestDirection[keyof typeof ServiceLevelChangeRequestDirection];


export function instanceOfServiceLevelChangeRequestDirection(value: any): boolean {
    for (const key in ServiceLevelChangeRequestDirection) {
        if (Object.prototype.hasOwnProperty.call(ServiceLevelChangeRequestDirection, key)) {
            // @ts-ignore
            if (ServiceLevelChangeRequestDirection[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ServiceLevelChangeRequestDirectionFromJSON(json: any): ServiceLevelChangeRequestDirection {
    return ServiceLevelChangeRequestDirectionFromJSONTyped(json, false);
}

export function ServiceLevelChangeRequestDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelChangeRequestDirection {
    return json as ServiceLevelChangeRequestDirection;
}

export function ServiceLevelChangeRequestDirectionToJSON(value?: ServiceLevelChangeRequestDirection | null): any {
    return value as any;
}

