import {
    ClientOnboardingBrowseProfilesJobStep,
    ClientOnboardingFindProfileUsingAIJobStep,
    ClientOnboardingInviteFreelancerJobStep,
    ClientOnboardingCompanyFreelancersJobStep,
    ClientOnboardingDiscoverMaltStrategyJobStep,
    type ClientOnboardingJob,
} from './openapi';
import type {ShallowTranslationKey} from '#build/i18n';
import {navbarEventBus} from '#imports';

export type ProductTourInfo = {
    type: 'ProductTour';
    content: ShallowTranslationKey;
    ctaLabel: ShallowTranslationKey;
    target: string;
    dataTestId: string;
    path?: string;
    job: ClientOnboardingJob;
    position: 'left' | 'right' | 'top' | 'bottom';
    beforeOpen?: () => void;
};

export type DialogInfo = {
    type: 'Dialog';
    title: ShallowTranslationKey;
    content: ShallowTranslationKey;
    ctaLabel: ShallowTranslationKey;
    link: ShallowTranslationKey;
    linkLabel: ShallowTranslationKey;
    dataTestId: string;
    path: string;
    job: ClientOnboardingJob;
    beforeOpen?: () => void;
};

export type ComponentInformation = ProductTourInfo | DialogInfo;

export type StepInfo = {
    component?: ComponentInformation;
    buttonState: 'NOTSTARTED' | 'ONGOING' | 'DONE';
    job?: ClientOnboardingJob;
    redirectUrl?: string;
};

export const browseProfileSteps: Record<ClientOnboardingBrowseProfilesJobStep, StepInfo> = {
    [ClientOnboardingBrowseProfilesJobStep.NotStarted]: {
        buttonState: 'NOTSTARTED',
    },
    [ClientOnboardingBrowseProfilesJobStep.SearchBox]: {
        component: {
            type: 'ProductTour',
            content: 'client.onboarding.product.tour.search.input.content',
            ctaLabel: 'client.onboarding.product.tour.search.input.cta',
            target: 'data-client-onboarding-search-input',
            dataTestId: 'client-onboarding-search-input-product-tour',
            path: '/project-client/find-freelancers',
            job: 'browseProfiles',
            position: 'bottom',
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingBrowseProfilesJobStep.ProfileOnSearchResults]: {
        component: {
            type: 'ProductTour',
            content: 'client.onboarding.product.tour.browse.profiles.content',
            ctaLabel: 'client.onboarding.product.tour.browse.profiles.cta',
            target: "data-client-browse-profiles-product-tour='0'",
            dataTestId: 'client-onboarding-browse-profiles-product-tour',
            path: '/s',
            job: 'browseProfiles',
            position: 'right',
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingBrowseProfilesJobStep.ProposeProjectButton]: {
        component: {
            type: 'ProductTour',
            content: 'client.onboarding.product.tour.project.proposal.content',
            ctaLabel: 'client.onboarding.product.tour.project.proposal.cta',
            target: 'data-client-onboarding-propose-a-project',
            dataTestId: 'client-onboarding-propose-a-project-product-tour',
            path: '/profile',
            job: 'browseProfiles',
            position: 'left',
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingBrowseProfilesJobStep.Done]: {
        buttonState: 'DONE',
    },
};

export const findProfileUsingAISteps: Record<ClientOnboardingFindProfileUsingAIJobStep, StepInfo> = {
    [ClientOnboardingFindProfileUsingAIJobStep.NotStarted]: {
        buttonState: 'NOTSTARTED',
    },
    [ClientOnboardingFindProfileUsingAIJobStep.PostProjectBox]: {
        component: {
            type: 'ProductTour',
            content: 'client.onboarding.product.tour.find.profiles.using.ai.content',
            ctaLabel: 'client.onboarding.product.tour.find.profiles.using.ai.cta',
            target: 'data-client-onboarding-find-profiles-using-ai',
            dataTestId: 'client-onboarding-find-profiles-using-ai-product-tour',
            path: '/project-client/find-freelancers',
            job: 'findProfileUsingAi',
            position: 'left',
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingFindProfileUsingAIJobStep.Done]: {
        buttonState: 'DONE',
    },
};

export const inviteFreelancerSteps: Record<ClientOnboardingInviteFreelancerJobStep, StepInfo> = {
    [ClientOnboardingInviteFreelancerJobStep.NotStarted]: {
        buttonState: 'NOTSTARTED',
    },
    [ClientOnboardingInviteFreelancerJobStep.InviteFreelancer]: {
        component: {
            type: 'ProductTour',
            content: 'client.onboarding.product.tour.invite.a.freelancer.content',
            ctaLabel: 'client.onboarding.product.tour.invite.a.freelancer.cta',
            target: 'data-client-onboarding-invite-freelancer',
            dataTestId: 'client-onboarding-invite-freelancer-product-tour',
            job: 'inviteFreelancer',
            position: 'right',
            beforeOpen: () => navbarEventBus.emit('request:navbar-desktop-full-with-tab-expanded', {tab: 'HireFreelancers'}),
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingInviteFreelancerJobStep.Done]: {
        buttonState: 'DONE',
    },
};

export const discoverMaltStrategySteps: Record<ClientOnboardingDiscoverMaltStrategyJobStep, StepInfo> = {
    [ClientOnboardingDiscoverMaltStrategyJobStep.NotStarted]: {
        buttonState: 'NOTSTARTED',
    },
    [ClientOnboardingDiscoverMaltStrategyJobStep.MaltStrategyDialog]: {
        component: {
            type: 'Dialog',
            title: 'client.onboarding.modal.malt.strategy.title',
            content: 'client.onboarding.modal.malt.strategy.content',
            ctaLabel: 'client.onboarding.modal.malt.strategy.cta',
            link: 'client.onboarding.modal.malt.strategy.link',
            linkLabel: 'client.onboarding.modal.malt.strategy.link.label',
            dataTestId: 'client-onboarding-malt-strategy-modal',
            path: '/project-client/find-freelancers',
            job: 'discoverMaltStrategy',
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingDiscoverMaltStrategyJobStep.FindAMaltStrategy]: {
        component: {
            type: 'ProductTour',
            content: 'client.onboarding.product.tour.malt.strategy.content',
            ctaLabel: 'client.onboarding.product.tour.find.profiles.using.ai.cta',
            target: 'data-client-onboarding-find-a-malt-strategy-consultant',
            dataTestId: 'client-onboarding-find-a-malt-strategy-product-tour',
            path: '/project-client/find-freelancers',
            job: 'discoverMaltStrategy',
            position: 'top',
        },
        buttonState: 'ONGOING',
    },
    [ClientOnboardingDiscoverMaltStrategyJobStep.Done]: {
        buttonState: 'DONE',
    },
};

export function companyFreelancersSteps(
    favoriteFreelancersPoolListElement: Element | null,
): Record<ClientOnboardingCompanyFreelancersJobStep, StepInfo> {
    return {
        [ClientOnboardingCompanyFreelancersJobStep.NotStarted]: {
            buttonState: 'NOTSTARTED',
        },
        [ClientOnboardingCompanyFreelancersJobStep.FreelancerPool]: {
            component: {
                type: 'ProductTour',
                content: 'client.onboarding.product.tour.freelancer.pool.content',
                ctaLabel: 'client.onboarding.product.tour.freelancer.pool.cta',
                target: 'data-client-onboarding-freelancer-pool',
                dataTestId: 'client-onboarding-freelancer-pool-product-tour',
                job: 'companyFreelancers',
                position: 'right',
                beforeOpen: () => navbarEventBus.emit('request:navbar-desktop-full-with-tab-expanded', {tab: 'HireFreelancers'}),
            },
            buttonState: 'ONGOING',
        },
        [ClientOnboardingCompanyFreelancersJobStep.FavoriteList]: {
            component: favoriteFreelancersPoolListElement
                ? {
                      type: 'ProductTour',
                      content: 'client.onboarding.product.tour.freelancers.pool.list.content',
                      ctaLabel: 'client.onboarding.product.tour.freelancers.pool.list.cta',
                      target: 'data-client-onboarding-freelancer-pool-favorite-list',
                      dataTestId: 'client-onboarding-freelancer-pool-favorite-list-product-tour',
                      path: '/client/freelancers/',
                      job: 'companyFreelancers',
                      position: 'top',
                  }
                : {
                      type: 'ProductTour',
                      content: 'client.onboarding.product.tour.create.favorite.list.content',
                      ctaLabel: 'client.onboarding.product.tour.create.favorite.list.cta',
                      target: 'data-client-onboarding-create-favorite-list',
                      dataTestId: 'client-onboarding-create-favorite-list-product-tour',
                      path: '/client/freelancers/',
                      job: 'companyFreelancers',
                      position: 'left',
                  },
            buttonState: 'ONGOING',
        },
        [ClientOnboardingCompanyFreelancersJobStep.Done]: {
            buttonState: 'DONE',
        },
    };
}
