/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClientOnboardingBrowseProfilesJobStep = {
    NotStarted: 'NOT_STARTED',
    SearchBox: 'SEARCH_BOX',
    ProfileOnSearchResults: 'PROFILE_ON_SEARCH_RESULTS',
    ProposeProjectButton: 'PROPOSE_PROJECT_BUTTON',
    Done: 'DONE'
} as const;
export type ClientOnboardingBrowseProfilesJobStep = typeof ClientOnboardingBrowseProfilesJobStep[keyof typeof ClientOnboardingBrowseProfilesJobStep];


export function instanceOfClientOnboardingBrowseProfilesJobStep(value: any): boolean {
    for (const key in ClientOnboardingBrowseProfilesJobStep) {
        if (Object.prototype.hasOwnProperty.call(ClientOnboardingBrowseProfilesJobStep, key)) {
            // @ts-ignore
            if (ClientOnboardingBrowseProfilesJobStep[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClientOnboardingBrowseProfilesJobStepFromJSON(json: any): ClientOnboardingBrowseProfilesJobStep {
    return ClientOnboardingBrowseProfilesJobStepFromJSONTyped(json, false);
}

export function ClientOnboardingBrowseProfilesJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboardingBrowseProfilesJobStep {
    return json as ClientOnboardingBrowseProfilesJobStep;
}

export function ClientOnboardingBrowseProfilesJobStepToJSON(value?: ClientOnboardingBrowseProfilesJobStep | null): any {
    return value as any;
}

