/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClientOnboardingFindProfileUsingAIJobStep = {
    NotStarted: 'NOT_STARTED',
    PostProjectBox: 'POST_PROJECT_BOX',
    Done: 'DONE'
} as const;
export type ClientOnboardingFindProfileUsingAIJobStep = typeof ClientOnboardingFindProfileUsingAIJobStep[keyof typeof ClientOnboardingFindProfileUsingAIJobStep];


export function instanceOfClientOnboardingFindProfileUsingAIJobStep(value: any): boolean {
    for (const key in ClientOnboardingFindProfileUsingAIJobStep) {
        if (Object.prototype.hasOwnProperty.call(ClientOnboardingFindProfileUsingAIJobStep, key)) {
            // @ts-ignore
            if (ClientOnboardingFindProfileUsingAIJobStep[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClientOnboardingFindProfileUsingAIJobStepFromJSON(json: any): ClientOnboardingFindProfileUsingAIJobStep {
    return ClientOnboardingFindProfileUsingAIJobStepFromJSONTyped(json, false);
}

export function ClientOnboardingFindProfileUsingAIJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientOnboardingFindProfileUsingAIJobStep {
    return json as ClientOnboardingFindProfileUsingAIJobStep;
}

export function ClientOnboardingFindProfileUsingAIJobStepToJSON(value?: ClientOnboardingFindProfileUsingAIJobStep | null): any {
    return value as any;
}

