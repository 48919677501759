
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  AllServiceLevelWithFeesResource,
  ServiceLevelChangeRequest,
  ServiceLevelStatusResource,
  ServiceLevelWithFeesResource,
} from '../models';
import {
    AllServiceLevelWithFeesResourceFromJSON,
    AllServiceLevelWithFeesResourceToJSON,
    ServiceLevelChangeRequestFromJSON,
    ServiceLevelChangeRequestToJSON,
    ServiceLevelStatusResourceFromJSON,
    ServiceLevelStatusResourceToJSON,
    ServiceLevelWithFeesResourceFromJSON,
    ServiceLevelWithFeesResourceToJSON,
} from '../models';

export interface RequestChangeOnServiceLevelRequest {
    serviceLevelChangeRequest: ServiceLevelChangeRequest;
}

/**
 * 
 */
export class ServiceLevelApi extends runtime.BaseAPI {

    /**
     * This endpoint would give information on all the service levels available on the platform, with their associated standard fees. Since the fees depend also on the country, the country that would be chosen for it would be either the country of the company of the connected user (if available), or the taken from the host of the request (f.e. FR for calls done through malt.fr)
     */
    async getAllServiceLevelDefinitionsRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<AllServiceLevelWithFeesResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<AllServiceLevelWithFeesResource>({
            path: `/client-settings/api/servicelevel/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=AllServiceLevelWithFeesResourceFromJSON(response._data); }
        return response;
    }

    /**
     * This endpoint would give information on all the service levels available on the platform, with their associated standard fees. Since the fees depend also on the country, the country that would be chosen for it would be either the country of the company of the connected user (if available), or the taken from the host of the request (f.e. FR for calls done through malt.fr)
     */
    async getAllServiceLevelDefinitions(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<AllServiceLevelWithFeesResource> {
        const response = await this.getAllServiceLevelDefinitionsRaw(initOverrides);
        return response._data;
    }

    /**
     * This endpoint returns the current fees and service level applied to the company of the connected user. This endpoint should be only called by a logged-in client, otherwise an error would be returned.
     */
    async getCurrentServiceLevelWithFeesRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<ServiceLevelWithFeesResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<ServiceLevelWithFeesResource>({
            path: `/client-settings/api/servicelevel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=ServiceLevelWithFeesResourceFromJSON(response._data); }
        return response;
    }

    /**
     * This endpoint returns the current fees and service level applied to the company of the connected user. This endpoint should be only called by a logged-in client, otherwise an error would be returned.
     */
    async getCurrentServiceLevelWithFees(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<ServiceLevelWithFeesResource> {
        const response = await this.getCurrentServiceLevelWithFeesRaw(initOverrides);
        return response._data;
    }

    /**
     * This endpoint gives an overall status on the options that the user has concerting the service level. It informs about the current service level and possible transitions. If the transition to another service level is not possible, an error code with the reason is given. 
     */
    async getStatusOnServiceLevelRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<ServiceLevelStatusResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<ServiceLevelStatusResource>({
            path: `/client-settings/api/servicelevel/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=ServiceLevelStatusResourceFromJSON(response._data); }
        return response;
    }

    /**
     * This endpoint gives an overall status on the options that the user has concerting the service level. It informs about the current service level and possible transitions. If the transition to another service level is not possible, an error code with the reason is given. 
     */
    async getStatusOnServiceLevel(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<ServiceLevelStatusResource> {
        const response = await this.getStatusOnServiceLevelRaw(initOverrides);
        return response._data;
    }

    /**
     * Request the transition for the company of the connected user to a new service level. The request needs to be validated before the company upgrades/downgrades to the requested service level. 
     */
    async requestChangeOnServiceLevelRaw(requestParameters: RequestChangeOnServiceLevelRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        if (requestParameters['serviceLevelChangeRequest'] == null) {
            throw new runtime.RequiredError(
                'serviceLevelChangeRequest',
                'Required parameter "serviceLevelChangeRequest" was null or undefined when calling requestChangeOnServiceLevel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<void>({
            path: `/client-settings/api/servicelevel/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceLevelChangeRequestToJSON(requestParameters['serviceLevelChangeRequest']),
        }, initOverrides);

        return response;
    }

    /**
     * Request the transition for the company of the connected user to a new service level. The request needs to be validated before the company upgrades/downgrades to the requested service level. 
     */
    async requestChangeOnServiceLevel(serviceLevelChangeRequest: ServiceLevelChangeRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.requestChangeOnServiceLevelRaw({ serviceLevelChangeRequest: serviceLevelChangeRequest }, initOverrides);
    }

}
